<template xmlns="http://www.w3.org/1999/html">
  <div>
      <div class="row mb-3">
          <div class="col-12">
              <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                  <input type="radio" class="btn-check" name="btnradio-faze-aktivni" id="btnradio-faze-aktivni" autocomplete="off" :checked="aktivniFaze == 'aktivni'">
                  <label @click="getPozadavkyNakupPolozky('aktivni')" class="btn btn-outline-secondary" for="btnradio-faze-aktivni">Aktivní</label>

                  <input type="radio" class="btn-check" name="btnradio-faze-aktivni" id="btnradio-faze-archiv" autocomplete="off" :checked="aktivniFaze == 'archiv'">
                  <label @click="getPozadavkyNakupPolozky('archiv')" class="btn btn-outline-secondary" for="btnradio-faze-archiv">Archív</label>
              </div>
          </div>
      </div>
      <hr />
      <template v-if="aktivniFaze == 'archiv'">
          <div  class="row mb-3">
              <div class="col-6">
                  <div class="btn-group" role="group" aria-label="Basic radio toggle button group">

                      <template v-for="(rokItem, key) in roky">

                          <input type="radio" class="btn-check" name="btnradio-rok" :id="'btnradio-rok-' + key" autocomplete="off" :checked="rok == rokItem">
                          <label @click="filtrRok(rokItem)" class="btn btn-outline-primary bg-gradient" :for="'btnradio-rok-' + key">{{ rokItem }}</label>

                      </template>

                  </div>
              </div>
              <div class="col-6">

                <the-autocomplete
                    placeholder="Zadejte název vozíku / evidčíslo / výrobce / model"
                    adresa="get-vzv"
                    sablona="vzv-long"
                    hodnota="id_polozky"
                    value="vzv"
                    id="vzv"
                    parametry=""
                    @ac-vzv="FiltrEvidCislo"
                />

              </div>
          </div>
          <div  class="row mb-3">
              <div class="col-9" >
                  <div class="btn-group" role="group" aria-label="Basic radio toggle button group">

                      <template v-for="(mesicItem, key) in mesice">

                          <input type="radio" class="btn-check" name="btnradio-mesic" :id="'btnradio-mesic-' + key" autocomplete="off" :checked="mesic == (key + 1)">
                          <label @click="filtrMesic((key + 1))" class="btn btn-outline-primary bg-gradient" :for="'btnradio-mesic-' + key">{{ mesicItem }}</label>

                      </template>

                  </div>
              </div>
              <div class="col-3">

                  <div class="btn-group" role="group" aria-label="Basic radio toggle button group">

                      <input type="radio" class="btn-check" name="btnradio-uzivatel" id="btnradio-uzivatel-337" autocomplete="off" :checked="idUzivatele == 337">
                      <label @click="filtrUzivatele(337)" class="btn btn-outline-primary bg-gradient" for="btnradio-uzivatel-337">Dostál</label>

                      <input type="radio" class="btn-check" name="btnradio-uzivatel" id="btnradio-uzivatel-477" autocomplete="off" :checked="idUzivatele == 477">
                      <label @click="filtrUzivatele(477)" class="btn btn-outline-primary bg-gradient" for="btnradio-uzivatel-477">Daniel</label>

                      <input type="radio" class="btn-check" name="btnradio-uzivatel" id="btnradio-uzivatel-312" autocomplete="off" :checked="idUzivatele == 312">
                      <label @click="filtrUzivatele(312)" class="btn btn-outline-primary bg-gradient" for="btnradio-uzivatel-312">Koubek</label>

                      <input type="radio" class="btn-check" name="btnradio-uzivatel" id="btnradio-uzivatel-17" autocomplete="off" :checked="idUzivatele == 17">
                      <label @click="filtrUzivatele(17)" class="btn btn-outline-primary bg-gradient" for="btnradio-uzivatel-17">VM</label>

                  </div>

              </div>
          </div>
          <hr />
      </template>

      <div class="row">
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead class="thead-light table-bordered">
                  <tr>
                      <th class="text-center align-middle">Datum vytvoření</th>
                      <th class="text-center align-middle">Vytvořil požadavek</th>
                      <th class="text-center align-middle">Vozík</th>
                      <th class="text-center align-middle">Pro zaměstnance</th>
                      <th class="text-center align-middle">Foto</th>
                      <th class="text-center align-middle">Položka</th>
                      <th class="text-center align-middle">Počet MJ</th>
                      <th class="text-center align-middle">Předp.<br />datum dodání</th>
                      <th class="text-center align-middle">Fáze objednávky</th>
                  </tr>
              </thead>
              <tbody class="table-bordered" :key="'pozadavkyNakupPolozkyKey-' + pozadavkyNakupPolozkyKey">
              <template v-for="(pozadavekNakupPolozka, id) in pozadavkyNakupPolozky">
                  <tr>
                      <td class="text-center align-middle" v-html="pozadavekNakupPolozka.datum_vytvoreni_format">

                      </td>
                      <td class="text-center align-middle">
                          {{ pozadavekNakupPolozka.tvurce }}
                      </td>
                      <td class="text-left align-middle">
                          {{ pozadavekNakupPolozka.vzv_evidcislo}}
                          {{ pozadavekNakupPolozka.vyrobce }} {{ pozadavekNakupPolozka.model}}
                      </td>
                      <td class="text-center align-middle">
                          {{ pozadavekNakupPolozka.odberatel }}
                      </td>
                      <td class="text-center" style="width : 100px">
                          <img :src="$adminUrl + pozadavekNakupPolozka.foto_cesta" class="img img-fluid" width="66%" />
                      </td>
                      <td class="text-center align-middle">
                          {{ pozadavekNakupPolozka.skladove_cislo }}<br />
                          {{ pozadavekNakupPolozka.nazev_polozky }}
                      </td>
                      <td class="text-center align-middle" v-bind:class="{pouzita: pozadavekNakupPolozka.pouzita == 1}">
                          {{ pozadavekNakupPolozka.pocet_mj }} {{ pozadavekNakupPolozka.merna_jednotka }}
                      </td>
                      <td class="text-center align-middle" >
                          <span v-if="pozadavekNakupPolozka.datum_predpokladaneho_dodani_format == ''">Nenastaveno</span>
                          <span v-else v-html="pozadavekNakupPolozka.datum_predpokladaneho_dodani_format"></span>
                      </td>
                      <td class="text-center align-middle" v-bind:class="pozadavekNakupPolozka.class_barva">
                          {{ pozadavekNakupPolozka.faze_pozadavku}}
                      </td>
                  </tr>
              </template>
              </tbody>
            </table>
          </div>
      </div>

  </div>
</template>

<script>

import TheAutocomplete from "@/components/form/TheAutocomplete";
import TheModalDialog from "@/components/TheModalDialog";
import TheToggle from "@/components/form/TheToggle";
import DetailPolozky from "@/views/DetailPolozky";
import axiosApiPost from "../axiosApiMethod";
import {bus} from "../main";

export default {
    name: "Objednavky",
    components: {TheModalDialog, TheToggle, DetailPolozky, TheAutocomplete},
    props: {
        aktivniFazeVstup : String,
    },
    data () {
      return {

          aktivniFaze : String,

          // Filtr
            mesic : 0,
            rok : 0,
            idUzivatele : 0,
            idPolozky : 0,

          mesice : [],
          roky : [],

          pozadavkyNakupPolozky : [],
          pozadavkyNakupPolozkyKey : 0
      }
    },
    created() {

      this.aktivniFaze = this.aktivniFazeVstup;

      this.fillMesice();
        this.fillRoky();

        this.getPozadavkyNakupPolozky(this.aktivniFaze);

        /*
        //
        // Odstraneni pozadavku
            bus.$on('modalDialogSave-' + 'modalOdstranitPozadavek', (result) =>
            {
                if(result == true)
                {
                    axiosApiPost('delete-pozadavek', {id_pozadavku: this.odstraneniIdPozadavku}, 'data').then( result => {

                        let tempThis = this;
                        _.remove(this.pozadavky, function(n) {

                            return n.id_pozadavku == tempThis.odstraneniIdPozadavku;
                        });

                        this.odstraneniIdPozadavku = 0;
                        this.pozadavkyKey++;

                    });
                }
            });

        //
        // Odstraneni polozky
            bus.$on('modalDialogSave-' + 'modalOdstranitPozadavekPolozku', (result) =>
            {
                if(result == true)
                {
                    axiosApiPost('delete-pozadavek-polozka', {id_pozadavku_polozky: this.odstraneniIdPozadavkuPolozky}, 'data').then( result => {

                        let tempThis = this;
                        _.remove(this.pozadavkyPolozky, function(n) {

                          return n.id_pozadavku_polozky == tempThis.odstraneniIdPozadavkuPolozky;
                        });

                        this.odstraneniIdPozadavkuPolozky = 0;
                        this.pozadavkyPolozkyKey++;

                    });
                }
            });*/
    },
    methods: {
        getPozadavkyNakupPolozky(fazePolozky)
        {
            this.aktivniFaze = fazePolozky;

            if(this.aktivniFaze == 'predano_zamestnanci' && this.mesic == 0 && this.rok == 0)
            {
                const datumNow = new Date();
                this.mesic = datumNow.getMonth() + 1;
                this.rok = datumNow.getFullYear();
            }

            if(this.idUzivatele == 0)
            {
                this.idUzivatele = localStorage.getItem('userId');
            }

            var parametry = {
              faze : fazePolozky,
            };

            if(this.mesic > 0)
            {
                parametry.mesic = this.mesic;
            }
            if(this.rok > 0)
            {
                parametry.rok = this.rok;
            }
            if(this.idUzivatele > 0)
            {
                parametry.id_uzivatele = this.idUzivatele;
            }
            if(this.idPolozky > 0)
            {
                parametry.id_polozky_vzv = this.idPolozky;
            }

            if(this.aktivniFaze == 'archiv')
            {
                parametry['order_by'] = 'id_pozadavku';
                parametry['sort'] = 'desc';
            }

            this.pozadavkyNakupPolozky = {};

            axiosApiPost('get-pozadavky-nakup-polozky', parametry, 'data').then( result => {
                console.log(result);
                this.pozadavkyNakupPolozky = result;
                this.pozadavkyNakupPolozkyKey++;
            });
        },

       // Filtr
          filtrMesic(mesic)
          {
              this.mesic = mesic;
              this.getPozadavkyNakupPolozky('archiv');
          },
          filtrRok(rok)
          {
              this.rok = rok;
              this.getPozadavkyNakupPolozky('archiv');
          },
          filtrUzivatele(idUzivatele)
          {
              this.idUzivatele = idUzivatele;
              this.getPozadavkyNakupPolozky('archiv')
          },
          FiltrEvidCislo(idPolozky)
          {
              this.idPolozky = idPolozky;
              this.getPozadavkyNakupPolozky('archiv')
          },

          fillMesice()
          {
              this.mesice = [
                'Leden',
                'Únor',
                'Březen',
                'Duben',
                'Květen',
                'Červen',
                'Červenec',
                'Srpen',
                'Září',
                'Říjen',
                'Listopad',
                'Prosinec',
              ];
          },
          fillRoky()
          {
              let d = new Date();
              let prvniRok = 2021;
              let nyniRok = d.getFullYear();

              for(let rok = prvniRok; rok <= nyniRok; rok++)
              {
                  this.roky.push(rok);
              }

          },

    },
    filters: {
        numberFormat(value) {
            return _.replace(value, '.', ',');
        }
    },
    metaInfo() {
      return {
        title: "Požadavky nákup",
        meta: [
          { name: 'description', content:  'Aplikace pro intralogistiky'},
          { property: 'og:title', content: "Požadavky intralogistika"},
          { property: 'og:site_name', content: 'Požadavky intralogistika'},
          { property: 'og:type', content: 'website'},
          { name: 'robots', content: 'noindex,nofollow'}
        ]
      }
    }
}
</script>

<style scoped>

.pouzita {
  background-color: #00B5B8;
}

.wrapBlue {
  background-color: #00BDFF;
}

.wrapRed {
  background-color: #e83564;
}

.wrapGreen {
  background-color: #0cd000;
}

.wrapOrange {
  background-color: #ec7600;
}

</style>